import { Controller } from "@hotwired/stimulus"
import ahoy from "ahoy.js"

// Connects to data-controller="shamela--search-form"
export default class extends Controller {
  static values = {
    input: String,
  }

  static targets = ["form", "queryInput", "results", "fillSearchFiltersFrame"]

  connect() {
    if (this.inputValue.length != 0) {
      this.formTarget.requestSubmit()
    }

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.fillSearchFiltersFrameTarget.click()

          observer.disconnect()
        }
      })
    }, { threshold: 0 })

    observer.observe(this.formTarget)
  }

  searchResultClicked(event) {
    history.replaceState({ show_results: true }, "")

    ahoy.track("Shamela search result clicked", {
      hadith_id: event.target.dataset.hadithId,
      query: this.queryInputTarget.value,
    })
  }

  updateContent() {
    if (this.queryInputTarget.value.length == 0) {
      this.resultsTarget.innerHTML = ""
    }
  }
}
