import { Controller } from "@hotwired/stimulus"
import debounce from "lodash.debounce"

// Connects to data-controller="youtube"
export default class extends Controller {
  static targets = ["player", "scrollingCue", "rotatingCue", "scrollingCuesContainer"];
  activeCueIndex = -1;

  connect() {
    this.updateSubtitles = debounce(this.updateSubtitles.bind(this), 400);

    this.initialCueDataElement = document.getElementById("initial-cue-data");
    this.initialCueStartTime = parseInt(this.initialCueDataElement.dataset.start)

    // Load the YouTube IFrame API if it hasn't already been loaded
    if (typeof YT === "undefined" || typeof YT.Player === "undefined") {
      var tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";
      var firstScriptTag = document.getElementsByTagName("script")[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      // Set up a callback function to create the player once the API is loaded.
      window.onYouTubeIframeAPIReady = () => {
        this.player = new YT.Player("player", {
          host: "https://www.youtube-nocookie.com",
          videoId: this.data.get("link").split("v=")[1],
          playerVars: {
            "origin": window.location.host,
            "playsinline": 1,
            "start": this.initialCueStartTime,
            "hl": "ar",
            "modestbranding": 1,
            "rel": 0,
            "autoplay": 1,
          },
          events: {
            onReady: this.onPlayerReady.bind(this)
          },
        });
      };
    } else {
      // If the API loaded already, just change the showing video.
      this.player = new YT.Player("player", {
        host: "https://www.youtube-nocookie.com",
        videoId: this.data.get("link").split("v=")[1],
        playerVars: {
          "origin": window.location.host,
          "playsinline": 1,
          "start": this.initialCueStartTime,
          "hl": "ar",
          "modestbranding": 1,
          "rel": 0,
          "autoplay": 1,
        },
        events: {
          onReady: this.onPlayerReady.bind(this)
        },
      });
    }

    this.activeCueIndex = -1;

    if (this.scrollingCueTargets.length > 0) {
      this.activeCueIndex = this.scrollingCueTargets.findIndex((cue) => parseInt(cue.dataset.endTime) >= this.initialCueStartTime);
    } else {
      this.activeCueIndex = this.rotatingCueTargets.findIndex((cue) => parseInt(cue.dataset.endTime) >= this.initialCueStartTime);
    }

    this.activateCue(this.activeCueIndex);

    const setScrollingCuesContainerHeight = () => {
      if (this.playerTarget.offsetHeight != 0) {
        this.updateScrollingCuesContainerHeight();
      } else {
        setTimeout(setScrollingCuesContainerHeight, 100);
      }
    };

    setScrollingCuesContainerHeight();
  }

  updateScrollingCuesContainerHeight() {
    this.scrollingCuesContainerTarget.style.height = `${this.playerTarget.offsetHeight - 68}px`;
  }

  disconnect() {
    this.player.destroy();
    cancelAnimationFrame(this.animationFrameID);
  }

  onPlayerReady() {
    if (this.initialCueStartTime == 0 && this.player.getCurrentTime() > 0) {
      this.player.seekTo(0);
    }

    this.updateSubtitles();
    this.animationFrameID = requestAnimationFrame(this.updateSubtitles);
  }

  updateSubtitles() {
    cancelAnimationFrame(this.animationFrameID);

    if (this.player.getPlayerState() != YT.PlayerState.PLAYING) {
      this.animationFrameID = requestAnimationFrame(this.updateSubtitles);
      return;
    }

    let currentCueEndTime = this.getCurrentCueEndTime();
    let playerCurrentTime = this.player.getCurrentTime();

    if (Math.abs(playerCurrentTime - currentCueEndTime) > 1) {
      var updated = false;

      for (var i = 0; i < this.getCueCount(); ++i) {
        if (!updated && this.getCueEndTime(i) >= playerCurrentTime) {
          updated = true;
          this.activateCue(i);
        } else {
          this.deactivateCue(i);
        }
      }
    } else if (playerCurrentTime >= currentCueEndTime) {
      this.deactivateCue(this.activeCueIndex);
      ++this.activeCueIndex;
      this.activateCue(this.activeCueIndex, 'smooth');
    }

    this.animationFrameID = requestAnimationFrame(this.updateSubtitles);
  }

  activateCue(cueIndex, behavior = 'instant') {
    if (this.rotatingCueTargets.length > 0) {
      this.rotatingCueTargets[cueIndex].classList.add("flex");
      this.rotatingCueTargets[cueIndex].classList.remove("hidden");
    }

    if (this.scrollingCueTargets.length > 0) {
      this.scrollingCueTargets[cueIndex].classList.add(...["rounded-lg", "bg-accent"]);
      this.scrollingCuesContainerTarget.scrollTo({
        top: this.scrollingCueTargets[cueIndex].offsetTop - this.scrollingCuesContainerTarget.offsetTop - 1 - 192 + (this.scrollingCueTargets[cueIndex].offsetHeight / 2),
        behavior
      })
    }

    this.activeCueIndex = cueIndex;
  }

  deactivateCue(cueIndex) {
    if (this.rotatingCueTargets.length > 0) {
      this.rotatingCueTargets[cueIndex].classList.add("hidden");
      this.rotatingCueTargets[cueIndex].classList.remove("flex");
    }

    if (this.scrollingCueTargets.length > 0) {
      this.scrollingCueTargets[cueIndex].classList.remove(...["rounded-lg", "bg-accent"]);
    }
  }

  getCurrentCueEndTime() {
    if (this.scrollingCueTargets.length > 0) {
      return parseInt(this.scrollingCueTargets[this.activeCueIndex].dataset.endTime);
    } else {
      return parseInt(this.rotatingCueTargets[this.activeCueIndex].dataset.endTime);
    }
  }

  getCueEndTime(cueIndex) {
    if (this.scrollingCueTargets.length > 0) {
      return parseInt(this.scrollingCueTargets[cueIndex].dataset.endTime);
    } else {
      return parseInt(this.rotatingCueTargets[cueIndex].dataset.endTime);
    }
  }

  getCueCount() {
    if (this.scrollingCueTargets.length > 0) {
      return this.scrollingCueTargets.length;
    } else {
      return this.rotatingCueTargets.length;
    }
  }

  seekToSecond(event) {
    event.preventDefault();

    document.getElementById("medium-transcriptions-search-results").innerHTML = "";

    this.player.seekTo(event.target.closest("span").dataset.startTime);
    this.player.playVideo();

    const newUrl = window.location.href.replace(window.location.search, "");
    const newParams = new URLSearchParams({ cue: event.target.closest("a").dataset.cueId }).toString();

    history.replaceState(history.state, "", `${newUrl}?${newParams}`);
  }
}
