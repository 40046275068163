import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="yamlify"
export default class extends Controller {
  connect() {
    if (typeof (Yamli) == "object" && Yamli.init({ uiLanguage: document.documentElement.lang, zIndexBase: 10 })) {
      Yamli.global.resetYamli(true)

      const checkYamli = setInterval(() => {
        if (typeof window.Yamli !== "undefined") {
          Yamli.yamlifyClass("yamlify", {
            settingsPlacement: "inside",
            showTutorialLink: false,
            showDirectionLink: false,
            settingsXOffset: 8
          })

          const trixElement = document.getElementById("yamlify-trix")

          if (trixElement) {
            Yamli.yamlify("yamlify-trix", {
              settingsPlacement: "inside",
              showTutorialLink: false,
              insidePlacementPadding: 8,
              settingsYOffset: 8,
              generateOnChangeEvent: true
            })
          }

          document.getElementById("yamli_reload_script").remove()

          clearInterval(checkYamli)
        }
      }, 100)
    }
  }
}
